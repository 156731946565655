<template>
    <div class="discount-mask"></div>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'

const Route = useRoute()
const { $lang } = useNuxtApp()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

let url = ''

if (Route.name === 'go-id') {
    try {
        if (Route.params.id.includes('-direct-')) {
            const newurl = (Route.params.id as string).replace('-direct-', '')
            await navigateTo(newurl, {
                redirectCode: 302,
            })
        } else {
            url = await $fetch<string>(
                endpoints.general.shortLink.offer.replace('_ID_', Route.params.id as string),
                {
                    method: 'GET',
                    baseURL,
                    headers: buildHeaders(),
                },
            )

            await navigateTo(url, {
                redirectCode: 302,
                open: {
                    target: '_self',
                },
                external: true,
            })
        }
    } catch (e) {
        console.log('Error inside offers mask: ', { e })

        throw createError({
            statusCode: 500,
            message: $lang.views.discountMask.unexpected_error,
        })
    }
}

if (Route.name === 'open-slug') {
    try {
        const result = await $fetch<Api.Responses.General.UseCoupon | Api.Responses.ResourceNotFound>(
            endpoints.general.useCoupon.path.replace('_SLUG_', Route.params.slug as string),
            {
                method: 'GET',
                baseURL,
                headers: buildHeaders(),
            },
        )

        if (result.feedback === 'data_success') {
            url = await $fetch<string>(
                endpoints.general.shortLink.coupon.replace('_ID_', String(result.data.coupon.id)),
                {
                    method: 'GET',
                    baseURL,
                    headers: buildHeaders(),
                },
            )

            await navigateTo(url, {
                redirectCode: 302,
                open: {
                    target: '_self',
                },
                external: true,
            })
        }
    } catch (e) {
        console.log('Error inside coupons mask: ', { e })

        throw createError({
            statusCode: 500,
            message: $lang.views.discountMask.error_obtaining_coupon,
        })
    }
}
</script>
